/* third-party */
import React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { useUpdateEffect } from "react-use";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
/* project */
import { AppbarLogo } from "app/components/appbar/logo";
import { ProjectPalette, ProjectTypography } from "app/theme";
import { AppBarPageModel, pages } from "app/components/appbar/data";
import {
  appbarcss,
  appbarlinkcss,
  appbartoolbarcss,
  socialMediaCss,
} from "app/components/appbar/styles";

export function Appbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const url = window.location.href;
  const title = "Zimmerman - Transform Data Into Meaningful Information";

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleShareOpen = () => {
    setOpenShare(true);
  };

  const handleShareClose = () => {
    setOpenShare(false);
  };

  useUpdateEffect(() => {
    handleDrawerClose();
  }, [location.pathname]);

  return (
    <React.Fragment>
      <AppBar css={appbarcss} position="fixed">
        <Container maxWidth="lg">
          <Toolbar disableGutters css={appbartoolbarcss}>
            <IconButton
              onClick={() => navigate("/")}
              css={`
                padding: 0;
              `}
            >
              <AppbarLogo />
            </IconButton>
            <Box
              sx={{
                gap: "44px",
                display: { xs: "none", sm: "none", md: "flex" },
              }}
            >
              {pages.map((page: AppBarPageModel) => (
                <div key={page.title}>
                  <NavLink
                    to={page.path}
                    css={appbarlinkcss(location.pathname === page.path)}
                  >
                    {page.title}
                    <hr
                      css={`
                        margin: 0;
                      `}
                    />
                  </NavLink>
                </div>
              ))}
            </Box>
            <Box
              sx={{
                gap: "30px",
                display: { xs: "flex", sm: "flex", md: "none" },
              }}
            >
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={open ? handleShareClose : handleShareOpen}
                >
                  <ShareIcon fontSize="large" />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                >
                  {open ? (
                    <CloseIcon fontSize="large" />
                  ) : (
                    <MenuIcon fontSize="large" />
                  )}
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        hideBackdrop
        sx={{
          width: "100vw",
          flexShrink: 0,
          top: { xs: 56, sm: 64 },
          height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          "& .MuiDrawer-paper": {
            width: "100vw",
            flexShrink: 0,
            top: { xs: 56, sm: 64 },
            background: ProjectPalette.primary.dark,
            height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              gap: "36px",
              padding: "2rem 0",
              display: { xs: "flex", md: "none" },
              flexDirection: "column",
              a: {
                fontSize: ProjectTypography.h5?.fontSize,
                fontWeight: "normal",
              },
            }}
          >
            {pages.map((page: AppBarPageModel) => (
              <div key={page.title}>
                <NavLink
                  to={page.path}
                  css={appbarlinkcss(location.pathname === page.path)}
                >
                  {page.title}
                </NavLink>
              </div>
            ))}
          </Box>
        </Container>
      </Drawer>
      <Drawer
        sx={{
          bottom: 0,
          width: "100vw",
          height: "33vh",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            bottom: 0,
            width: "100vw",
            height: "33vh",
            flexShrink: 0,
            background: ProjectPalette.common.white,
          },
        }}
        variant="temporary"
        anchor="bottom"
        open={openShare}
        onClose={handleShareClose}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              gap: "36px",
              padding: "2rem",
              display: { xs: "flex", md: "none" },
              flexDirection: "column",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleShareClose}
              sx={{
                alignSelf: "flex-end",
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
            <Typography
              variant="subtitle2"
              sx={{
                alignSelf: "center",
              }}
            >
              Share the link via
            </Typography>
            <Box
              sx={{
                gap: "4rem",
                display: "flex",
                alignSelf: "center",
                flexDirection: "row",
              }}
            >
              <TwitterShareButton url={url} title={title}>
                <div css={socialMediaCss}>
                  <TwitterIcon
                    fontSize="large"
                    htmlColor={ProjectPalette.common.white}
                  />
                </div>
              </TwitterShareButton>

              <LinkedinShareButton url={url} title={title}>
                <div css={socialMediaCss}>
                  <LinkedInIcon
                    fontSize="large"
                    htmlColor={ProjectPalette.common.white}
                  />
                </div>
              </LinkedinShareButton>

              <FacebookShareButton url={url} quote={title}>
                <div css={socialMediaCss}>
                  <FacebookIcon
                    fontSize="large"
                    htmlColor={ProjectPalette.common.white}
                  />
                </div>
              </FacebookShareButton>
            </Box>
          </Box>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
