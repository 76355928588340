import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import { HashLink } from "react-router-hash-link";
import { pages } from "app/components/appbar/data";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { AppbarLogo } from "app/components/appbar/logo";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  footerCss,
  footerLinkCss,
  footerLogoCss,
  footerPrivacyCss,
  footerAddressCss,
  footerContainerCss,
  footerAddressLinksCss,
} from "app/components/footer/style";
import IconButton from "@mui/material/IconButton";

function Footer() {
  return (
    <footer css={footerCss}>
      <Container maxWidth="lg" css={footerContainerCss}>
        <div css={footerLogoCss}>
          <AppbarLogo />
        </div>
        <div css={footerLinkCss}>
          {pages.map((page) => (
            <Link key={page.path} to={page.path}>
              {page.title}
            </Link>
          ))}
          <Link to="/team/work-with-us">Careers</Link>
          <HashLink to="/contact#newsletter">Subscribe</HashLink>
        </div>
        <div css={footerAddressCss}>
          <p>
            Tel:{" "}
            <a href="tel:+31202134466" target="_blank">
              +3120 213 4466
            </a>{" "}
            <br />{" "}
            <a href="mailto:info@zimmerman.team" target="_blank">
              info@zimmerman.team
            </a>
          </p>
          <p>
            Zimmerman B.V. <br /> Keizersgracht 555 <br /> 1017 DR Amsterdam{" "}
            <br /> The Netherlands
          </p>
          <div css={footerAddressLinksCss}>
            <a
              href="https://linkedin.com/company/zimmerman-team"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon htmlColor="#fff" style={{ fontSize: "3.6rem" }} />
            </a>
            <a
              href="https://twitter.com/zimmerman_team"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon
                htmlColor="#fff"
                fontSize="large"
                style={{ fontSize: "3.6rem" }}
              />
            </a>
            <a
              href="https://github.com/zimmerman-team"
              target="_blank"
              rel="noreferrer"
            >
              <GitHubIcon
                htmlColor="#fff"
                fontSize="large"
                style={{ fontSize: "3.6rem" }}
              />
            </a>
          </div>
        </div>
        <div css={footerPrivacyCss}>
          <p>
            ⓒ 2022 Zimmerman B.V. All Rights Reserved
            <span css="width: 2px;height: 2px;border-radius: 50%;background: #868686;margin: 0 5px;" />
            <a
              target={"_blank"}
              href="https://drive.google.com/file/d/1andhlQEoaEq5qDxMbtnApXiZborsg-bG/view"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <IconButton
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <ArrowUpwardIcon htmlColor="black" fontSize="large" />
          </IconButton>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
